@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  @apply flex flex-col min-h-screen font-work-sans
}

footer {
  @apply mt-auto
}

.sidebar a.active {
  @apply underline font-bold
}

.table-heading {
  @apply border font-bold p-2 whitespace-nowrap text-start
}

.table-data {
  @apply p-2 font-semibold border whitespace-nowrap
}

.table-heading-custom-pages {
  @apply border p-2 whitespace-nowrap
}

.table-data-custom-pages {
  @apply text-center p-2 border whitespace-nowrap
}

.custom-blue {
  @apply bg-[#5608F8]
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.normal-cursor {
  cursor: default;
}

.loading-cursor {
  cursor: wait;
}